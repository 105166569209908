/* eslint-disable */
import {pegaDados,getLatByCep} from '../../services/GenericAPI'
import {Filtro, Where} from '../../models/filtro/filtro'
import {PerfilSCH} from '../../search/PerfilSCH'
import {EventBus} from '../../helpers/event-bus'
import {Perfil} from '../../models/perfil'

const url = '/perfil'

const state = {
  all: [],
  markers: [],
  filtro: new Filtro(),
  perfil: new Perfil(),
}

const getters = {
  listaPerfil: (state) => {
    return state.perfil
  },
  pegaFiltro: (state) => {
    return state.filtro
  },
  pegaMarkes: (state) => {
    return state.markers
  }
}

const actions = {
  setFiltro({commit}, perPage) {
     commit('SET_FILTRO', PerfilSCH(perPage))
  },

  setPerfil({commit}, perfil) {
    commit('SET_PERFIL', perfil)
  },

  async setAllPerfil({commit}, {onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url, state.filtro)).data
      let perfil = list.data[0]

      getLatByCep(perfil.cep).then(response => {
        perfil.coodernadas = response.data.results[0].geometry.location
        perfil.formatted_address = response.data.results[0].formatted_address
        state.markers.push( {position: {lat: perfil.coodernadas.lat, lng: perfil.coodernadas.lng}, texto: perfil.cidade})
        commit('SET_PERFIL', perfil)
        EventBus.$emit('PERFIL_PROCESSADO', true)
        onSucesso()
      }).catch(e => {

        perfil.coordenadas = {
          lat: -19.7898341,
          lng: -42.1539741
        }
        state.markers.push( {position: {lat: perfil.coodernadas.lat, lng: perfil.coodernadas.lng}, texto: 'Caratinga'})
        commit('SET_PERFIL', perfil)
        console.log(e)
        onSucesso()
      })

    } catch (error) {
      onErro()
    }

  }
}

const mutations = {
  SET_PERFIL(state, perfil) {
      state.perfil = perfil
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
