<template>
  <div id="inspire">
    <v-card class="overflow-hidden toobar">
      <v-app-bar absolute color="white" elevate-on-scroll scroll-target="#scrolling-techniques-7"
                 class="appScrolling" id="navapp">
        <v-system-bar :height="30" window fixed id="system_bar">
          <v-row style="padding: 0px">
            <v-col cols="12" sm="6" style="text-align: center">
              <small style="width: 37%">
              <span style="padding: 2%; padding-right: 2%">
                <a v-show="perfil.fone_whatsapp" :href="'https://web.whatsapp.com/send?phone=+55'+ perfil.fone_whatsapp"
                   target="_blank"
                   style="text-decoration: none; color: #696969;padding-right: 16px">
                   <v-icon aria-label="whatsapp">mdi-whatsapp</v-icon>
                   {{ maskTel(perfil.fone_whatsapp) }}
                </a>
                <a :href="'tel:' + perfil.fone_1" style="text-decoration: none; color: #696969">
                <v-icon aria-label="fone">mdi-phone</v-icon>
                {{ maskTel(perfil.fone_1) }}
                  <span v-show="perfil.fone_2">
                  / {{ maskTel(perfil.fone_2) }}
                  </span>
               </a>
               </span>
                <span style="padding-left: 2%; padding-right: 2%"><v-icon aria-label="email">mdi-mail</v-icon> {{ perfil.email }}</span>
              </small>
            </v-col>
            <v-col cols="12" sm="6" class="spc" style="padding: 5px; text-align: right">
              <v-icon aria-label="facebook" @click="AbriEmNovaAba(perfil.link_facebook)" style="margin: 1%; color: #3D60B0!important;">
                mdi-facebook
              </v-icon>
              <v-icon aria-label="instagram" @click="AbriEmNovaAba(perfil.link_instagram)" style="margin: 1%; color: #CE2861!important;">
                mdi-instagram
              </v-icon>
            </v-col>
          </v-row>
        </v-system-bar>

        <v-app-bar-nav-icon class="ismobile" @click="AtivarMobile()" small></v-app-bar-nav-icon>

        <v-container style="margin-top: 50px">
          <v-toolbar-title>
            <div @click="recarregar('/')" id="home" style="cursor: pointer">
              <div class="div_logo"></div>
            </div>
          </v-toolbar-title>
        </v-container>
        <v-spacer></v-spacer>

        <v-toolbar-items class="toobar_dash" style="margin-top: 50px">
          <!--DR AMELIE-->
          <v-btn text @click="irPara('/doutor', 'doutor')" id="doutor">Dra. Amelie</v-btn>

          <!--ATENDIMETNO-->
          <v-btn text @click="irPara('/atendimento', 'atendimento')" id="atendimento">Local de Atendimento</v-btn>

          <!--DOENÇAS VASCULARES   -->
          <v-btn text @click="irPara('/tipo_dor', 'doenca')" id="doenca">Tipos de Dores</v-btn>

          <!--CONVENIOS-->
          <v-btn text @click="irPara('/tratamento', 'tratamento')" id="tratamento">Tratamento</v-btn>

          <!--BLOG-->
          <v-btn text @click="irPara('/blog', 'blog')" id="blog">Blog</v-btn>

          <!--NA MIDIA-->
          <v-btn text @click="irPara('/na_midia', 'na_midia')" id="na_midia">Na Mídia</v-btn>

          <!--CONTATO-->
          <v-btn text @click="irPara('/contato', 'Contato')" id="Contato">Contato</v-btn>

          <!--LIVRO-->
          <v-btn text @click="irPara('/livro', 'Livro')" id="Livro">Livro</v-btn>
        </v-toolbar-items>
      </v-app-bar>
      <v-sheet id="scrolling-techniques-7" class="overflowyauto">
        <div id="topo">
          <br>
          <div id="_conteudo">
            <router-view/>
          </div>
          <br>
          <footers></footers>
        </div>
        <v-navigation-drawer v-model="drawer" absolute temporary style="background: rgba(255,255,255,0.9)">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Menu</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>
          <v-list dense>
            <v-list-item v-for="item in listaMenu" :key="item.path" link @click="irPara2(item.path, item.path)">
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </v-sheet>
    </v-card>
  </div>
</template>

<script async>
/* eslint-disable */
  import router from './router'
  import miscMixin from './helpers/misc'
  import {mapGetters} from 'vuex'

  export default {
    name: 'Dash',
    mixins: [miscMixin],
    data: () => ({
      drawer: null,
      collapseOnScroll: false,
      delay: '800',
      listaMenu: [
        {
          title: 'Home',
          path: ''
        },
        {
          title: 'Dra. Amelie',
          path: 'doutor'
        },
        {
          title: 'Locais de atendimento',
          path: 'atendimento'
        },
        {
          title: 'Tipos de Dores',
          path: 'tipo_dor'
        },
        {
          title: 'Tratamento',
          path: 'tratamento'
        },
        {
          title: 'Blog',
          path: 'blog'
        },
        {
          title: 'Na Mídia',
          path: 'na_midia'
        },
        {
          title: 'Contato',
          path: 'contato'
        },
        {
          title: 'Livro',
          path: 'livro'
        },
      ]
    }),
    methods: {
      AtivarMobile() {
        this.drawer = !this.drawer
      },
      irPara(path, id) {
        this.scrollTo('inspire')
        this.isActive(id)
        router.push({path: path}).catch(err => {
        })

      },
      irPara2(path, id) {
        this.scrollTo('inspire')
        router.push({path: '/' + path}).catch(err => {
        })
      },
    },
    created() {
      // this.scrollTo('inspire')
    },
    mounted() {
      // if (window.Worker) {
        // Permite usar o AOS com vuetify
        var inspire = document.getElementById('inspire')
        window.onscroll = function() {
          'use strict'
          if (document.documentElement.scrollTop >= 50) {
            inspire.classList.add('scroll')
          } else {
            inspire.classList.remove('scroll')
          }
        }
        var width = window.screen.width
        if (width <= 500) {
          this.collapseOnScroll = true
        }

      // }

    },
    computed: {
      ...mapGetters('perfil', {perfil: 'listaPerfil'})
    },
  }
</script>
