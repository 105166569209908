/* eslint-disable */
import {lista, pegaDados, processar} from '../../services/GenericAPI'
import {Filtro, Where} from '../../models/filtro/filtro'

import {LivroSCH} from '../../search/LivroSCH'
import {EventBus} from '../../helpers/event-bus'
import {Livro} from '../../models/livro'

const url = '/livro'

const state = {
  filtro: new Filtro(),
  livro: new Livro(),
}

const getters = {
  pegaFiltro: (state) => {
    return state.filtro
  },
  pegaLivro: (state) => {
    return state.livro !== undefined ? state.livro : new Livro()
  }
}

const actions = {
  setFiltro({commit}, perPage) {
     commit('SET_FILTRO', LivroSCH(perPage))
  },
  async setLivro({commit},{onSucesso,onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data
      commit('SET_LIVRO', list.data[0])
      onSucesso()
    } catch (error) {
      onErro()
    }

  },
}

const mutations = {
  SET_LIVRO(state, livro) {
      state.livro = livro
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
