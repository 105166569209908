<template>
  <v-app id="app">
    <router-view/>
    <popup-whats v-show="perfil.fone_whatsapp"></popup-whats>
  </v-app>
</template>

<script async>
/* eslint-disable */
  import AOS from 'aos'
  import {mapGetters} from 'vuex'

  export default {
    name: 'App',
    components: {
      PopupWhats: () => import('./components/PopupWhats.vue')
    },
    created() {
      AOS.init();
    },
    computed: {
      ...mapGetters('perfil', {perfil: 'listaPerfil'})
    },
  }
</script>
<style>
  .zap {
    display: block !important;
    position: fixed !important;
    bottom: 40px !important;
    right: 50px !important;
    z-index: 10 !important;
    font-size: 18px !important;
    border: none !important;
    outline: none !important;
    color: white !important;
    cursor: pointer !important;
    padding: 15px !important;
    border-radius: 4px !important;
  }
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
</style>
