<template>
  <div class="footers">
    <v-footer text-xs-center class="foot" dense>
      <v-container class="footer_top">
        <v-row wrap class="footer_colunas" >
          <v-col cols="12" sm="4" md="4" style=" color: white" class="animationLeft">
            <div @click="recarregar('/')" v-scrollanimation style="cursor: pointer">
              <div class="logo_footer "></div>
            </div>
            <small>
              <div v-scrollanimation class="txt_">CRM {{ perfil.crm }}</div>
              <div v-scrollanimation class="txt_"> {{ perfil.descr_especialidade_1 }}</div>
              <div v-scrollanimation class="txt_">{{ perfil.descr_especialidade_2 }}</div>
            </small>
            <div>
              <v-icon aria-label="facebook" @click="AbriEmNovaAba(perfil.link_facebook)" color="#3D60B0"
                      style="margin: 1%">mdi-facebook
              </v-icon>
              <v-icon aria-label="instagram" @click="AbriEmNovaAba(perfil.link_instagram)" color="#CE2861"
                      style="margin: 1%">mdi-instagram
              </v-icon>
            </div>
          </v-col>
          <v-col cols="12" sm="4" md="4" lg="4" class="animationUp">
            <h2 class="txt_" style="text-align: center; padding-top: 30px">Contato</h2>
            <div style="padding-top: 16px">
              <v-row justify="center" style="font-size: 14px; align-content: center">
                <div v-show="perfil.fone_whatsapp" style="padding: 0">
                   <span class="txt_">
                <a :href="'https://web.whatsapp.com/send?phone=+55'+ perfil.fone_whatsapp" target="_blank"
                   style="text-decoration: none; color: inherit">
                 <v-icon aria-label="whataspp" style=" color: #e55c5c">mdi-whatsapp</v-icon>
                  {{ maskTel(perfil.fone_whatsapp) }}
                </a>
               </span>
                </div>
              </v-row>
              <v-row justify="center" style="font-size: 14px; align-content: center">
                <div v-show="perfil.fone_1" style="padding: 0px" class="txt_">
                  <span class="txt_" v-scrollanimation>
                    <a :href="'tel:'+ perfil.fone_1" style="text-decoration: none; color: inherit">
                      <v-icon style=" color: #e55c5c" aria-label="fone">mdi-phone</v-icon>
                      {{ maskTel(perfil.fone_1) }}
                    </a>
                  </span>
                </div>
              </v-row>
              <v-row justify="center" style="font-size: 14px; align-content: center">
                <div v-show="perfil.fone_2" style="padding: 0px" class="txt_">
                  <span class="txt_">
                    <a :href="'tel:'+ perfil.fone_2" style="text-decoration: none; color: inherit">
                      <v-icon style=" color: #e55c5c" aria-label="fone2">mdi-phone</v-icon>
                      {{ maskTel(perfil.fone_2) }}
                    </a>
                  </span>
                </div>
              </v-row>
              <v-row justify="center" style="font-size: 14px; align-content: center">
                <div v-show="perfil.email" style="padding: 0px" class="txt_">
                  <span class="txt_" v-scrollanimation>
                      <v-icon style=" color: #e55c5c" aria-label="email">mdi-email</v-icon>
                      {{ perfil.email }}
                  </span>
                </div>
              </v-row>
            </div>

          </v-col>
          <v-col cols="12" sm="4" md="4" class="animationUp">
            <h2 class="txt_" style="padding-top: 30px">Endereço</h2>
            <div style="padding-top: 16px">
              <div class="txt_"> {{ perfil.endereco }}, {{ perfil.numero }} , {{ perfil.bairro }}</div>
              <div class="txt_">{{ perfil.cidade }} - {{ perfil.uf }} </div>
              <div class="txt_">CEP: {{ maskCep(perfil.cep) }}</div>
            </div>
          </v-col>
        </v-row>
        <br>
      </v-container>

      <div style="width: 100%; padding: 0px; margin: 0px">
        <v-row  class="rodape">
          <v-col cols="12">
           <span style="font-size: 12px;  color: white; padding: 1%">Copyrigth &ensp; &copy;{{ new Date().getFullYear() }} —
             <a href="http://www.prodatanet.com.br/"
                style="text-decoration: none; color: orange">Prodata Informática</a>
             e Cadastro Ltda. Todos os direitos reservados.</span>
          </v-col>
        </v-row>
      </div>
    </v-footer>
  </div>

</template>

<script async>
/* eslint-disable */
import miscMixin from '../helpers/misc'
import miscImageCropper from '../helpers/img-cropper'
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'ProFooter',
  mixins: [miscMixin, miscImageCropper],
  data() {
    return {
      coordenadas: [],
      show_mapa: false,
    }
  },
  methods: {
    ...mapActions('perfil', ['setAllPerfil', 'setFiltro', 'processarPerfil', 'setPerfil']),
  },
  created() {
    this.setFiltro('*')
    this.setAllPerfil({
      onSucesso: () => {
        this.show_mapa = true
        sessionStorage.setItem('sobre_mim', this.perfil.sobre_mim)
        this.preencheObjeto(this.perfil, '200')
      },
      onErro: () => {
        this.show_mapa = false
      }
    })
  },
  computed: {
    ...mapGetters('perfil', {perfil: 'listaPerfil'})
  }
}
</script>
