/* eslint-disable */
import Vue from 'vue'
import Router from 'vue-router'
import Dash from "./Dash";

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: '',
      component: Dash,
      children: [
        {
          path: '/',
          name: 'home',
          component: () => import(/* webpackChunkName: "home" */ './views/Home/Home.vue')
        },
        {
          path: '/doutor',
          name: 'doutor',
          component: () => import(/* webpackChunkName: "doutor" */ './views/Doutor/Doutor.vue')
        },
        {
          path: '/tipo_dor',
          name: 'doenca',
          component: () => import(/* webpackChunkName: "dor" */ './views/Doencas/Doencas.vue')
        },
        {
          path: '/tipo_dor_detalhe',
          name: 'doenca_detalhe',
          component: () => import(/* webpackChunkName: "artigo_dor" */ './views/Doencas/DoencaDetalhes.vue')
        },
        {
          path: '/tratamento',
          name: 'Tratamento',
          component: () => import(/* webpackChunkName: "tratamento" */ './views/Tratamento/ListarArtigos.vue')
        },
        {
          path: '/tratamento/artigo/:tratamento_id',
          name: 'ArtigoTratamento',
          component: () => import(/* webpackChunkName: "tratamento_artigo" */ './views/Tratamento/Artigo.vue')
        },
        {
          path: '/blog',
          name: 'blog',
          component: () => import(/* webpackChunkName: "log" */ './views/Blog/Blog.vue')
        },
        {
          path: '/atendimento',
          name: 'Atendimento',
          component: () => import(/* webpackChunkName: "atendimento" */ './views/Atendimento/Atendimento.vue')
        },
        {
          path: '/contato',
          name: 'Contato',
          component: () => import(/* webpackChunkName: "contato" */ './views/Contato/Contato.vue')
        },
        {
          path: '/na_midia',
          name: 'NaMidia',
          component: () => import(/* webpackChunkName: "na_midia" */ './views/NaMidia/NaMidia.vue')
        },
        {
          path: '/na_midia/artigo/:blog_id',
          name: 'AtigoNaMidia',
          component: () => import(/* webpackChunkName: "na_midia_artigo" */ './views/NaMidia/Artigo.vue')
        },
        {
          path: '/livro',
          name: 'Livro',
          component: () => import(/* webpackChunkName: "na_midia_artigo" */ './views/Livro/Livro.vue')
        },
      ]
    },
    {
      path: '*',
      name: '404',
      component: () => import(/* webpackChunkName: "404" */ './views/404.vue')
    }
  ]
})
