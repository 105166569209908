/* eslint-disable */
import {pegaDados} from '../../services/GenericAPI'
import {Filtro} from '../../models/filtro/filtro'
import {BannerSCH} from '../../search/BannerSCH'
import {EventBus} from '../../helpers/event-bus'

const url = '/banner';

const state = {
  banner_array: [],
  filtro: new Filtro()
};

const getters = {
  pegaBannerArray: (state) => {
    if (state.banner_array.length > 0) {
      state.banner_array.forEach(function (bl) {
        if (bl.imagens === undefined){
          bl.imagens =[]
        }
        if (!bl.is_video) {
          bl.imagens = bl.urls_imagem
        }
        if (bl.banner_imagem.imagens === undefined){
          bl.banner_imagem.imagens =[]
          bl.banner_imagem.imagens = bl.banner_imagem.urls_imagem
        }

      })
    }
    return state.banner_array
  },
  pegaFiltro: (state) => {
    return state.filtro
  },
};


const actions = {

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', BannerSCH(perPage))
  },

  async setAllBanners({commit}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data;
      commit('SET_BANNER_ARRAY', list.data)
      EventBus.$emit('LISTA_PROCESSADA', true)
    } catch (error) {
      console.log(error)
      EventBus.$emit('LISTA_PROCESSADA', false)
    }
  },
};

const mutations = {
  SET_BANNER_ARRAY(state, banner_array) {
    state.banner_array = banner_array
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
