/* eslint-disable */
import axios from 'axios'

export const API = axios.create({
  // withCredentials: false,
  baseURL: process.env.VUE_APP_API_URL,
  // headers: {
    // 'Cache-Control': 'max-age=3600',
    // 'Pragma': 'no-cache',
    // 'Expires': '0',
  // },
  BASE_URL: '/'

})

export function setToken(token) {
  API.defaults.headers.common.Authorization = `Bearer ${token}`
}
