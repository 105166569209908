/* eslint-disable */
import {pegaDados, processar} from '../../services/GenericAPI'
import {Filtro, Where} from '../../models/filtro/filtro'

import {getTratamentoSCH, getTratRelevanteSCH, TratamentoSCH} from '../../search/TratamentosSCH'
import {EventBus} from '../../helpers/event-bus'
import {Tratamento} from '../../models/tratamentos'

const url = '/tratamento'

const state = {
  all: [],
  allLista: [],
  allListaRelevante: [],
  filtro: new Filtro(),
  tratamento: new Tratamento(),
  filtro_relevante: new Filtro(),
}

const getters = {
  listaTratRelevante: (state) => {
    return state.allListaRelevante !== undefined ? state.allListaRelevante : []
  },
  listaAllTratamentos: (state) => {
    return state.allLista !== undefined ? state.allLista : []
  },

  listaTratamentos: (state) => {
    return state.all
  },

  pegaTratamento: (state) => {
    return state.tratamento
  },
  pegaFiltro: (state) => {
    return state.filtro
  },
  pegaFiltroRelevante: (state) => {
    return state.filtro_relevante
  }
}

const actions = {
  setFiltroRelevante({commit}, perPage) {
    commit('SET_FILTRO_RELEVANTE', getTratRelevanteSCH(perPage))
  },
  searchItem({commit}, search) {
    state.filtro.filters.addSearch(['titulo', 'ordem'] ,
      "%"+search+"%",
      'ilike'
    )
  },
  async getItem({commit}, id) {
    var filtro = getTratamentoSCH('tratamento_id', id, '=')
    const list = (await pegaDados(url + '/get', filtro)).data
    commit('SET_TRATAMENTO', list.data[0])
    EventBus.$emit('GET_CONCLUIDO', true, list.data[0])
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', TratamentoSCH(perPage))
  },

  setTratamento({commit}, tratamento) {
    commit('SET_TRATAMENTO', tratamento)
  },

  async setAllTratRelevante({ commit }) {
    try {
      var list
      var filtro = sessionStorage.getItem('filtro_relevante')
      if (filtro !==  '' && filtro !== undefined) {
        filtro =  JSON.parse(filtro)
        list = (await pegaDados(url+'/get', filtro)).data
        if (list.data.length <= 5) {
          list = (await pegaDados(url+'/get', state.filtro_relevante)).data
        }
      } else {
        list = (await pegaDados(url+'/get', state.filtro_relevante)).data
      }
      commit('SET_ALL_RELEVANTE', list)
      EventBus.$emit('RELEVANTE_PROCESSADA', true)
    } catch (error) {
      EventBus.$emit('RELEVANTE_PROCESSADA', false)
      console.log(error)
    }
  },

  async setAllTratamentos({commit}) {
    try {
      const list = (await pegaDados(url + '/get', state.filtro)).data
      commit('SET_TRATAMENTOS', list)
      EventBus.$emit('LISTA_PROCESSADA', true)
    } catch (error) {
      EventBus.$emit('LISTA_PROCESSADA', false)
    }

  },

  async processarTratamento({commit}, {tratamento, acao}) {
    try {
      let res = await processar(url, tratamento, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      this.dispatch('tratamento/setAllTratamentos')
      EventBus.$emit('PROCESSO_CONCLUIDO', true, true, 'Tratamentos')
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      EventBus.$emit('PROCESSO_CONCLUIDO', false, true, 'TratamentoCadastrar')
    }
  }
}

const mutations = {
  SET_ALL_LIST (state, subgrupos) {
    state.allLista = subgrupos
  },
  SET_TRATAMENTOS(state, tratamentos) {
    state.all = tratamentos
  },
  SET_TRATAMENTO(state, tratamento) {
    state.tratamento = tratamento
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_FILTRO_RELEVANTE(state, filtro) {
    state.filtro_relevante= filtro
  },
  SET_ALL_RELEVANTE (state, allLista) {
    state.allListaRelevante = allLista
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
