/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Perfil {
  constructor () {
    this.perfil_id = uuidV4()
    this.nome = ''
    this.sobre_mim = ''
    this.descr_especialidade_1 = ''
    this.descr_especialidade_2 = ''
    this.nome_clinica = ''
    this.crm = ''
    this.fone_1 = ''
    this.fone_2 = ''
    this.email = ''
    this.endereco = ''
    this.numero = ''
    this.complemento = ''
    this.bairro = ''
    this.cidade = ''
    this.uf = ''
    this.cep = ''
    this.link_facebook = ''
    this.link_instagram = ''
    this.fone_whatsapp = ''

    this.imagens = []
  }
}
