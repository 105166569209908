/* eslint-disable */
import {processar} from '../../services/GenericAPI'
import {Lead} from '../../models/lead'

const url = 'lead'
const state = {
  lead: new Lead(),
}
const getters = {

  pegaLead: (state) => {
    return state.lead
  },
}
const actions = {
  setLead({commit}, lead) {
    commit('SET_LEAD', lead)
  },

  async processarLead({commit}, {lead, acao, onSucesso, onErro}) {
    try {
      console.log('processarLead: ', lead)
      let res = await processar(url, lead, acao)

      onSucesso()
    } catch (error) {
      console.log('ERROR: ', error)
      if (error.response.data !== undefined) {
        if (error.response.data.details !== undefined) {
          onErro(error.response.data.details[0].mensagem)
        } else {
          onErro()
        }
      } else {
        onErro()
      }

    }
  },
}
const mutations = {
  SET_LEAD(state, lead) {
    state.lead = lead
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
