/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Tratamento {
  constructor () {
    this.tratamento_id = uuidV4()
    this.titulo = ''
    this.descricao = ''
    this.texto = ''
    this.ordem = ''
    this.imagens = []
  }
}
