/* eslint-disable */
import Vue from 'vue'
import Vuex from 'vuex'
import blog from './modules/blog'
import doenca from './modules/doenca'
import contato from './modules/contatos'
import instagram from './modules/instagram'
import banner from './modules/banner'
import perfil from './modules/perfil'
import local_atendimento from './modules/local_atendimento'
import tratamento from './modules/tratamentos'
import leads from './modules/leads'
import livro from './modules/livro'

Vue.use(Vuex)
export default new Vuex.Store({
  modules: {
    blog,
    livro,
    banner,
    perfil,
    leads,
    doenca,
    contato,
    tratamento,
    instagram,
    local_atendimento,
  }
})
