/* eslint-disable */
import {pegaDados, pegaLista} from '../../services/GenericAPI'
import {Where} from '../../models/filtro/filtro'
import {InstagramSCH} from '../../search/InstagramSCH'
import {EventBus} from '../../helpers/event-bus'

const url = '/instagram'

const state = {
  all: [],
}

const getters = {
  listaInstagram: (state) => {
    return state.all
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', InstagramSCH(perPage))
  },

  async setAllInstagram({commit}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data
      commit('SET_INSTAGRAM', list)
      EventBus.$emit('LISTA_PROCESSADA', true)
    } catch (error) {
      EventBus.$emit('LISTA_PROCESSADA', false)
    }
  }
}

const mutations = {
  SET_INSTAGRAM(state, instagram) {
    state.all = instagram
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
