/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Lead {
  constructor () {
    this.lead_id = uuidV4()
    this.nome = ''
    this.email = ''
    this.whatsapp = ''
  }
}
