/* eslint-disable */
export default {
  data: () => ({
    listaItens: [],
    array_scroll: [],
    info_alerta: '',
    cor_alerta: 'primary',
    show_alerta: false,
  }),
  methods: {
    removerMascara (str) {
      if (this.validarCampo(str)) {
        return str.replace(/[^0-9]+/g, '')
      } else {
        return ''
      }
    },
    // ALERTA
    open_alerta(text, cor, tempo = 6) {
      this.info_alerta = text
      this.cor_alerta = cor
      this.show_alerta = true
      let _this = this
      let intervalo = setInterval(function () {
        _this.show_alerta = false
        clearInterval(intervalo)
      }, tempo * 1000)
    },
    fechar_alerta() {
      this.show_alerta = false
    },

    cloneObj(obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    itens(item) {
      this.listaItens = item
    },
    focusInput(id) {
      let loadTime = 10
      window.onload = function () {
        loadTime = window.performance.timing.domContentLoadedEventEnd - window.performance.timing.navigationStart;
      }
      let interval = setInterval(function () {
        let muniImpt = document.getElementById(id)
        if (muniImpt !== null) {
          muniImpt.focus()
        }
        clearInterval(interval)
      }, loadTime)
    },
    cortarString(str, tam) {
      if (this.validarCampo(str)) {
        if (str.length > tam) {
          str = str.slice(0, tam) + '...'
        }
      }
      return str
    },
    // MUDA COR DA LINHA
    isActive(id) {
      var toobar_dash = document.getElementsByClassName('toobar_dash')
      var lista = toobar_dash.item(0).children
      for(var i=0; i< lista.length; i++){
        var classes = lista[i].className.split(' ')
        var getIndex = classes.indexOf('toobar_is_active')

        if (lista[i].id === id){
          if (getIndex === -1) {
            classes.push('toobar_is_active')
            lista[i].className = classes.join(' ')
          }
        } else {
          if (getIndex > -1) {
            classes.splice(getIndex, 1)
            lista[i].className = classes.join(' ')
          }
        }
      }
    },
    validarCampo(campo) {
      if (campo !== undefined && campo !== null && campo !== '') {
        return true
      } else {
        return false
      }
    },
    scrollTo (id) {
      var elmnt = document.getElementById(id);
      elmnt.scrollIntoView({behavior: "smooth"});
    },

    inverterData2(newData) {
      var xvals = ''
      if (this.validarCampo(newData)) {
        xvals = newData.split('-')
        return xvals[2] + '/' + xvals[1] + '/' + xvals[0]
      } else {
        return xvals
      }
    },

    maskCep (cep) {
      if (this.validarCampo(cep)) {
        return cep.replace(/(\d{2})(\d{3})(\d{3})/g, '\$1.\$2\-\$3')
      }
    },

    maskTel (srt) {
      if (this.validarCampo(srt)){
        if (srt.length <=10) {
          return srt.replace(/(\d{2})(\d{4})(\d{4})/g, '(\$1) \$2\-\$3')
        } else {
          return srt.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/g, '(\$1) \$2.\$3\-\$4')
        }
      }
    },
    getWordSearch(str){
      var array = str.split(" ");
      var array2 = [];
      for (var i = 0; i< array.length; i++) {
        if (array[i].length >= 4) {
          array2.push(array[i])
        }
      }
      // console.log('array: ', array2)
      return array2
    },
    retira_acentos(str)
    {

      var com_acento = "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ!"

      var sem_acento = "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr "
      var novastr="";
      for(var i=0; i<str.length; i++) {
        var troca=false;
        for (var a=0; a<com_acento.length; a++) {
          if (str.substr(i,1)===com_acento.substr(a,1)) {
            novastr+=sem_acento.substr(a,1);
            troca=true;
            break;
          }
        }
        if (troca===false) {
          novastr+=str.substr(i,1);
        }
      }
      return novastr;
    },
    AbriEmNovaAba(path){
      var a = document.createElement('a')
      a.href = path
      a.target = '_blank'
      a.click()
    },
    recarregar(path){
      var a = document.createElement('a')
      a.href = path
      a.click()
    }
  },

}
