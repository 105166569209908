/* eslint-disable */
import {lista, pegaDados, pegaLista, processar} from '../../services/GenericAPI'
import {Filtro, Where} from '../../models/filtro/filtro'

import {DoencaSCH, DoencaVascularSCH, DoencaVenosaSCH, getDoencaSCH} from '../../search/DoencaSCH'
import {EventBus} from '../../helpers/event-bus'
import {Doenca} from '../../models/doenca'

const url = '/doenca'

const state = {
  all: [],
  all_venosas: [],
  allLista: [],
  filtro: new Filtro(),
  doenca: new Doenca(),
}

const getters = {
  listaDoencas: (state) => {
    return state.all
  },

  listaDoencasVenosas: (state) => {
    return state.all_venosas
  },

  pegaDoenca: (state) => {
    return state.doenca
  },
  pegaFiltro: (state) => {
    return state.filtro
  },

}

const actions = {
  async setAllListDoenca({ commit }) {
    const list = (await lista(url + '/all')).data
    commit('SET_ALL_LIST', list)
    EventBus.$emit('DOENCAS_ALL_CONCLUIDO', true)
  },

  searchItem({commit}, search) {
    state.filtro.filters.addFilter('descricao' ,
      "%"+search+"%",
      'like'
    )
  },
  async getItem({commit}, id) {
     var filtro = getBaseSCH('doenca_id', id, '=')
     const list = (await pegaLista(url, filtro)).data
      commit('DOENCAS', list.data[0])
     EventBus.$emit('GET_CONCLUIDO', true)
  },

  async getItemDoenca({commit}, id) {
     var filtro = getDoencaSCH('doenca_id', id, '=')
     const list = (await pegaLista(url, filtro)).data
     commit('SET_DOENCA', list.data[0])
     EventBus.$emit('GET_DOENCA_CONCLUIDO', true)
  },

  setFiltro({commit}, perPage) {
     commit('SET_FILTRO', DoencaSCH(perPage))
  },

  setDoenca({commit}, doenca) {
     commit('SET_DOENCA', doenca)
  },

  async setAllDoencas({commit}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data
      commit('SET_DOENCAS', list)

      EventBus.$emit('LISTA_PROCESSADA', true)
    } catch (error) {
      EventBus.$emit('LISTA_PROCESSADA', false)
    }
  },

  async setAllDoencasVenosa({commit}) {
    try {
      var filtro = DoencaVenosaSCH('*')
      const list = (await pegaDados(url+'/get', filtro)).data
      commit('SET_DOENCAS_VENOSAS', list.data)
      EventBus.$emit('LISTA_VENOSA_PROCESSADA', true)
    } catch (error) {
      EventBus.$emit('LISTA_VENOSA_PROCESSADA', false)
    }
  },

  async processarDoenca({commit}, {doenca, acao}) {
    try {
      let res = await processar(url, doenca, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      this.dispatch('doenca/setAllDoencas')
      EventBus.$emit('PROCESSO_CONCLUIDO', true, true, 'Doenca')
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      EventBus.$emit('PROCESSO_CONCLUIDO', false, true, 'DoencaCadastrar')
    }
  }
}

const mutations = {
  SET_ALL_LIST (state, subgrupos) {
    state.allLista = subgrupos
  },
  SET_DOENCAS(state, doencas) {
      state.all = doencas
  },

  SET_DOENCAS_VENOSAS(state, doencas) {
    state.all_venosas = doencas
  },

  SET_DOENCA(state, doenca) {
      state.doenca = doenca
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
