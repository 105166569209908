/* eslint-disable */
import {pegaDados} from '../../services/GenericAPI'
import {LocalAtendimentoSCH} from '../../search/LocalAtendimentoSCH'

const url = '/local-atendimento'

const state = {
  all: []
}

const getters = {
  listaLocalAtendimento: (state) => {
    return state.all
  },
}

const actions = {
  async setAllLocalAtendimento({commit},{onSucesso,onErro}) {
    try {
      var filtro = LocalAtendimentoSCH(1)
      const list = (await pegaDados(url+'/get', filtro)).data
      commit('SET_lOCAL_ATENDIMENTOS', list.data)
      onSucesso()
    } catch (error) {
      onErro()
    }
  },
}

const mutations = {
  SET_lOCAL_ATENDIMENTOS(state, doencas) {
      state.all = doencas
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
