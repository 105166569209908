/* eslint-disable */
import {pegaDados, pegaLista} from '../../services/GenericAPI'
import {Filtro} from '../../models/filtro/filtro'
import {BlogSCH, getBlogSCH} from '../../search/BlogSCH'
import {EventBus} from '../../helpers/event-bus'
import {Blog} from '../../models/blog'

const url = '/blog'

const state = {
  all: [],
  allLista: [],
  filtro: new Filtro(),
  blog: new Blog(),
}

const getters = {
  listaBlogs: (state) => {
    return state.all
  },

  pegaBlog: (state) => {
    return state.blog
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  async searchItem({commit}, search) {
    try {
      var filtro = getBlogSCH('blog_id', search, '=')
      const list = (await pegaDados(url+'/get', filtro)).data
      commit('SET_BLOG', list.data[0])
      EventBus.$emit('GET_SEARCH_CONCLUIDO', true, list.data[0])
    } catch (error) {
      EventBus.$emit('GET_SEARCH_CONCLUIDO', false,[])
    }
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', BlogSCH(perPage))
  },
  setBlog({commit}, blog) {
    commit('SET_BLOG', blog)
  },
  async setAllBlogs({commit}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data
      commit('SET_BLOGS', list)
      EventBus.$emit('LISTA_PROCESSADA', true)
    } catch (error) {
      EventBus.$emit('LISTA_PROCESSADA', false)
    }

  },
}

const mutations = {
  SET_ALL_LIST (state, subgrupos) {
    state.allLista = subgrupos
  },
  SET_BLOGS(state, blogs) {
    state.all = blogs
  },
  SET_BLOG(state, blog) {
    state.blog = blog
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
